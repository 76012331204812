import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Client interoperability";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "client-interoperability",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#client-interoperability",
        "aria-label": "client interoperability permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Client interoperability`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#annotated-services"
          }}>{`Annotated services`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#axios"
            }}>{`Axios`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`This page describes methods to achieve interoperability with some well-known clients.`}</p>
    <h2 {...{
      "id": "annotated-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#annotated-services",
        "aria-label": "annotated services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Annotated services`}</h2>
    <h3 {...{
      "id": "axios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#axios",
        "aria-label": "axios permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Axios`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/axios/axios"
      }}>{`Axios`}</a>{` works with Armeria annotated services without issues, except
when serializing arrays as query parameters. By default, arrays are serialized as `}<inlineCode parentName="p">{`?foo[]=1&foo[]=2`}</inlineCode>{`,
while Armeria expects `}<inlineCode parentName="p">{`?foo=1&foo=2`}</inlineCode>{`.`}</p>
    <p>{`This behavior can be modified by specifying a custom `}<inlineCode parentName="p">{`paramsSerializer`}</inlineCode>{` function when creating a new
Axios instance. For example, you can use the `}<a parentName="p" {...{
        "href": "https://github.com/ljharb/qs"
      }}>{`qs`}</a>{` library:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const axios = require('axios');
const qs = require('qs');

function paramsSerializer(params) {
  return qs.stringify(params, { arrayFormat: 'repeat' });
}

const client = axios.create({ paramsSerializer });
`}</code></pre>
    <p>{`If you prefer not to add an extra dependency, you can use the following function instead:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function paramsSerializer(params) {
  const parts = [];

  for (const [key, values] of Object.entries(params)) {
    if (values === null || typeof values === 'undefined') {
      continue;
    }

    for (const value of [values].flat()) {
      parts.push(\`\${key}=\${encodeURIComponent(value)}\`);
    }
  }

  return parts.join('&');
}
`}</code></pre>
    <p>{`The implementation is based on `}<a parentName="p" {...{
        "href": "https://github.com/axios/axios/blob/d99d5faac29899eba68ce671e6b3cbc9832e9ad8/lib/helpers/buildURL.js#L34-L54"
      }}>{`the default serializer in Axios`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      